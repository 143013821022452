import { Css, Icon } from "@homebound/beam";
import { SectionTitle } from "./atoms";
import { BlueprintReadyPlanComputeData } from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlanComputeDataEndpoint";
import { ReadyPlan } from "src/routes/cma/endpoints/reports";
import { formatNumberToString } from "src/utils";

type RenderCostChangesProps = {
  computeData: BlueprintReadyPlanComputeData;
  readyPlan: ReadyPlan;
  hasChanges?: boolean;
};

export function RenderCostChanges({ computeData, readyPlan, hasChanges = false }: RenderCostChangesProps) {
  const { directHardCostInCents, indirectHardCostInCents, softCostInCents } = computeData.computeReadyPlanCost!;
  const { sellableSqft } = computeData.computeProgramData!;
  const { direct_hard_cost_in_cents, indirect_hard_cost_in_cents, soft_cost_in_cents, hard_costs_per_sqft } = readyPlan;

  const newHardCostInCents = directHardCostInCents / 100 / sellableSqft!;

  return (
    <div>
      <SectionTitle label="Updated Costs" success={!hasChanges} />
      <div css={Css.df.fdc.gap1.mx5.$}>
        <CostLine label="Soft Cost" old={soft_cost_in_cents! / 100} newV={softCostInCents / 100} />
        <CostLine
          label="Indirect Hard Cost"
          old={indirect_hard_cost_in_cents! / 100}
          newV={indirectHardCostInCents / 100}
        />
        <CostLine label="Direct Hard Cost" old={direct_hard_cost_in_cents! / 100} newV={directHardCostInCents / 100} />
        <CostLine label="Hard Costs P/SF" old={hard_costs_per_sqft} newV={newHardCostInCents} />
      </div>
    </div>
  );
}

type LineItemProps = {
  label: string;
  old: number;
  newV: number;
};

// Bolds when inequality; prefixs values with $;
export const CostLine = ({ label, old, newV }: LineItemProps) => (
  <div css={Css.df.aic.jcsb.bb.bsDotted.bcGray700.if(old !== newV).smBd.$}>
    <div>{label}</div>
    <div css={Css.df.aic.gap(1.5).$}>
      <div>${formatNumberToString(old, true)}</div>
      <Icon inc={2} icon="arrowRight" />
      <span>${formatNumberToString(newV, true)}</span>
    </div>
  </div>
);
