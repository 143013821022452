import { BoundSelectField, Css, FormLines, HasIdAndName, SelectField, useModal } from "@homebound/beam";
import { required, useFormState } from "@homebound/form-state";
import { useSuspense } from "@rest-hooks/react";
import { Observer } from "mobx-react";
import { useLayoutEffect, useMemo, useRef, useState } from "react";
import { ConfirmationModal } from "src/components/ConfirmationModal";
import { LoadingBoundary } from "src/components/LoadingBoundary";
import { useController } from "src/hooks";
import {
  SaveUnderwritingReportEndpoint,
  SaveUnderwritingReportInput,
  UnderwritingReport,
} from "src/routes/cma/endpoints/reports";
import {
  SaveUnderwritingSubjectPropertyEndpoint,
  UnderwritingSubjectPropertyEndpoint,
  UnderwritingSubjectPropertyInput,
} from "src/routes/cma/endpoints/UnderwritingSubjectPropertyEndpoint";
import { BlueprintDevelopmentsEndpoint } from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintDevelopmentsEndpoint";
import { BlueprintOptionGroupsEndpoint } from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlanOptionsEndpoint";
import {
  BlueprintReadyPlan,
  BlueprintReadyPlansEndpoint,
} from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlansEndpoint";
import {
  LoadReadyPlanOptionsForm,
  ReadyPlanOptionsForm,
} from "src/routes/cma/steps/ready-plan/v2/ReadyPlanOptionsForm";
import { getPropertyTypeFromBpRp, getReadyPlanVersion } from "src/routes/cma/steps/readyPlanUtils";
import { Maybe } from "src/utils";
import { readyPlanStepIsReadOnly } from "src/utils/reports";
import { ReadyPlanNameLabel } from "./components/ReadyPlanNameLabel";
import { ConfigSelectionContext } from "./useConfigPreviewContext";

interface ReadyPlanConfigSelectionV2Props {
  report: UnderwritingReport;
}

export function ReadyPlanConfigSelectionV2({ report }: ReadyPlanConfigSelectionV2Props) {
  const { fetch, invalidate } = useController();
  const { openModal, closeModal } = useModal();
  const reportReadyPlan = report?.ready_plans?.[0];
  const [bpReadyPlanId, setBpReadyPlanId] = useState(reportReadyPlan?.bp_ready_plan_id);
  const isReadOnly = readyPlanStepIsReadOnly(report);

  const { uw_subj_property } = useSuspense(UnderwritingSubjectPropertyEndpoint, { dpid: report.dpid });
  const developmentsResult = useSuspense(BlueprintDevelopmentsEndpoint, { metro: report.reference_property!.metro });

  const developments: HasIdAndName[] = useMemo(
    () => developmentsResult?.map((d) => ({ id: d.id, name: d.name })) ?? [],
    [developmentsResult],
  );

  const configPreviewRef = useRef<HTMLDivElement | null>(null);
  const configPreviewEl = useMemo(() => document.createElement("div"), []);

  useLayoutEffect(() => {
    configPreviewRef.current!.appendChild(configPreviewEl);
  }, [configPreviewEl]);

  const subjectPropertyForm = useFormState<
    { devId: Maybe<string> },
    { uw_subj_property?: UnderwritingSubjectPropertyInput }
  >({
    config: { devId: { type: "value", rules: [required] } },
    init: {
      input: { uw_subj_property },
      map: ({ uw_subj_property }) => ({ devId: uw_subj_property?.development_id }),
    },
    readOnly: isReadOnly,
    autoSave: saveSubjectProperty,
  });

  // TODO: we either need to further split up the components or update the BE to delete RPs when this changes
  async function saveSubjectProperty() {
    await fetch(SaveUnderwritingSubjectPropertyEndpoint, {
      dpid: report.dpid,
      uw_subj_property: {
        development_id: subjectPropertyForm.devId.value!,
        development_name: developments.find((d) => d.id === subjectPropertyForm.devId.value)?.name!,
      },
    });
  }

  // Changing a readyPlan must reset all other fields beside Development
  async function createReadyPlanConfiguration(rp: BlueprintReadyPlan) {
    const input = {
      report: {
        dpid: report.dpid,
        ready_plans: [
          {
            property_type: getPropertyTypeFromBpRp(rp.type),
            bp_ready_plan_id: rp.id,
            bp_ready_plan_name: rp.name,
            bp_ready_plan_template_version: getReadyPlanVersion(rp),
            bp_ready_plan_option_ids: [],
            bp_ready_plan_options_json: {},
            lot_size: report?.reference_property?.lot_size,
          },
        ],
      } as SaveUnderwritingReportInput,
      versionId: report.id,
    };
    if (bpReadyPlanId && bpReadyPlanId !== rp.id) {
      openModal({
        content: (
          <ConfirmationModal
            confirmationMessage={"Changing ready plan will reset selections. Are you sure?"}
            onConfirmAction={async () => {
              setBpReadyPlanId(rp.id);
              await Promise.all([
                fetch(SaveUnderwritingReportEndpoint, input),
                // Force re-fetching optionGroups of the newly selected Ready Plan in case user is switching back and forth
                // FIXME: Feature is shelved till April '24 anyway
                // Some interaction between multiple resources along with formState are causing options to not be update
                // when changing ready plans within BoundSelectField.
                // This is a workaround until we can refactor or figure out the root cause.
                // Could be a nudge rest-hooks should be upgraded to give us more options to make calls or could just
                // be that we're using it wrong in these components. It's a little choppy in here.
                invalidate(BlueprintOptionGroupsEndpoint, {
                  devId: subjectPropertyForm.devId.value!,
                  bp_ready_plan_id: rp.id,
                }),
              ]);

              closeModal();
            }}
            title="Change Ready Plan"
            label="Confirm"
          />
        ),
      });
    } else {
      setBpReadyPlanId(rp.id);
      await fetch(SaveUnderwritingReportEndpoint, input);
    }
  }

  function renderForm() {
    return (
      <ConfigSelectionContext.Provider value={{ configPreviewEl }}>
        <div css={Css.df.$}>
          <div css={Css.mw75.$}>
            <FormLines compact>
              <BoundSelectField
                options={developments}
                label={"Development"}
                field={subjectPropertyForm.devId}
                placeholder="Select a development"
              />
              {subjectPropertyForm.devId.value && (
                <>
                  <LoadingBoundary>
                    <DevelopmentReadyPlanSelectField
                      onRPSelect={createReadyPlanConfiguration}
                      bpReadyPlanId={bpReadyPlanId}
                      devId={subjectPropertyForm.devId.value}
                      readOnly={subjectPropertyForm.readOnly}
                      readyPlanName={reportReadyPlan?.bp_ready_plan_name}
                    />
                  </LoadingBoundary>
                  {!subjectPropertyForm.readOnly && !!reportReadyPlan && reportReadyPlan.bp_ready_plan_id && (
                    <LoadReadyPlanOptionsForm report={report} devId={subjectPropertyForm.devId.value} />
                  )}
                  {/* bp_ready_plan_options_json only undefined on an inactive report that never had a rp configured */}
                  {subjectPropertyForm.readOnly && reportReadyPlan?.bp_ready_plan_options_json?.["optionGroups"] && (
                    <ReadyPlanOptionsForm
                      optionGroups={reportReadyPlan.bp_ready_plan_options_json["optionGroups"]}
                      devId={subjectPropertyForm.devId.value}
                      report={report}
                    />
                  )}
                </>
              )}
            </FormLines>
          </div>
          <div css={Css.mw25.$}>
            <div ref={configPreviewRef} />
          </div>
        </div>
      </ConfigSelectionContext.Provider>
    );
  }

  return <Observer>{() => renderForm()}</Observer>;
}

type DevelopmentReadyPlanSelectFieldProps = {
  devId: string;
  bpReadyPlanId?: string | null;
  onRPSelect: (rp: BlueprintReadyPlan) => void;
  readOnly: boolean;
  readyPlanName: Maybe<string>;
};

function DevelopmentReadyPlanSelectField(props: DevelopmentReadyPlanSelectFieldProps) {
  const { devId, bpReadyPlanId, onRPSelect, readOnly, readyPlanName } = props;
  const readyPlansResult = useSuspense(BlueprintReadyPlansEndpoint, { devId });
  const plans = useMemo(() => readyPlansResult ?? [], [readyPlansResult]);

  if (readOnly) {
    return (
      <div>
        <div css={Css.gray700.mbPx(4).$}>Ready Plan</div>
        <div css={Css.smMd.$}>{readyPlanName}</div>
      </div>
    );
  }

  return (
    <SelectField
      options={readyPlansResult ?? []}
      errorMsg={plans.length < 1 ? `No Ready Plans Configured for development:${devId}` : ""}
      label={"Ready Plan"}
      disabledOptions={readyPlansResult
        .filter((rp) => rp.type?.code === "MFU")
        .map((rp) => ({ value: rp.id, reason: "Multi Family RPs are not supported at this time." }))}
      placeholder="Select a ready plan"
      value={bpReadyPlanId}
      getOptionMenuLabel={(rp: BlueprintReadyPlan) => <ReadyPlanNameLabel readyPlan={rp} />}
      onSelect={async (v, rp) => onRPSelect(rp!)}
    />
  );
}
