import { Button, Css, ModalBody, ModalFooter, ModalHeader } from "@homebound/beam";
import { SaveUnderwritingReportEndpoint, UnderwritingReport } from "src/routes/cma/endpoints/reports";
import { BlueprintReadyPlanOptionGroup } from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlanOptionsEndpoint";
import { CustomLoadingBoundary } from "../LoadingBoundary";
import { SectionTitle } from "./components/atoms";
import { RenderOptionChanges } from "./components/RenderOptionChanges";
import { RenderProgramChanges } from "./components/RenderProgramChanges";

type BlueprintProjectUpdatesModalProps = {
  report: UnderwritingReport;
  optionGroups: BlueprintReadyPlanOptionGroup[];
  missingOptions: string[];
  addedOptions: string[];
  changedProgramData: { label: string; old: number; new: number }[];
  bpSelectedOptionIds: string[];
};

export function BlueprintProjectUpdatesModal(props: BlueprintProjectUpdatesModalProps) {
  const { report, optionGroups, missingOptions, addedOptions, changedProgramData, bpSelectedOptionIds } = props;

  const reportReadyPlan = report?.ready_plans?.[0]!;

  const allOptions = optionGroups?.flatMap((o) => o.options);

  return (
    <>
      <ModalHeader>Blueprint Project Changes</ModalHeader>
      <ModalBody>
        <>
          <div>
            The Blueprint project differs from the values saved in this report. The changes below must be saved to
            enable valuation accuracy.
          </div>
          <CustomLoadingBoundary fallback={<SectionTitle label="Checking Options" loading />}>
            <div css={Css.df.fdc.gap3.mt3.$}>
              {(missingOptions.length > 0 || addedOptions.length > 0) && (
                <RenderOptionChanges
                  missingOptions={missingOptions}
                  addedOptions={addedOptions}
                  allOptions={allOptions}
                />
              )}
              {changedProgramData.length > 0 && <RenderProgramChanges programDataChanges={changedProgramData} />}
            </div>
          </CustomLoadingBoundary>
        </>
      </ModalBody>
      <ModalFooter>
        <Button
          label="Save Changes"
          onClick={async () => {
            changedProgramData.forEach(({ label, new: newComputedValue }) => {
              // @ts-ignore
              reportReadyPlan[label] = newComputedValue;
            });

            // Get all option groups that have selected IDs for static use after a report has been finalized
            const bpSelectedRpOptionGroups = {
              optionGroups: optionGroups.map((d) => ({
                ...d,
                options: d.options.filter((o) => bpSelectedOptionIds.includes(o.id)),
              })) as BlueprintReadyPlanOptionGroup[],
            };

            const updatedReadyPlan = {
              ...reportReadyPlan,
              bp_ready_plan_option_ids: bpSelectedOptionIds,
              bp_ready_plan_options_json: bpSelectedRpOptionGroups,
            };

            await SaveUnderwritingReportEndpoint({
              report: {
                ...report,
                ready_plans: [updatedReadyPlan],
              },
              versionId: report.id,
            });

            window.location.reload();
          }}
          variant={"primary"}
        />
      </ModalFooter>
    </>
  );
}
