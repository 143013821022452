import { Css } from "@homebound/beam";
import { ReadyPlanOption } from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlanOptionsEndpoint";
import { SectionTitle } from "./atoms";

type RenderOptionChangesProps = {
  missingOptions: string[];
  addedOptions: string[];
  allOptions: ReadyPlanOption[];
};

export function RenderOptionChanges(props: RenderOptionChangesProps) {
  const { missingOptions, addedOptions, allOptions } = props;

  return (
    <div>
      <SectionTitle label="Option Changes" success={false} />
      <OptionChange changedOptions={missingOptions} allOptions={allOptions} type="Removed" />
      <OptionChange changedOptions={addedOptions} allOptions={allOptions} type="Added" />
    </div>
  );
}

type OptionChangeProps = {
  changedOptions: string[];
  allOptions: ReadyPlanOption[];
  type: "Added" | "Removed";
};

function OptionChange(props: OptionChangeProps) {
  const { changedOptions, allOptions, type } = props;

  if (!changedOptions.length) return null;

  return (
    <div css={Css.mx4.$}>
      <div css={Css.smBd.$}>{`Options ${type}:`}</div>
      <ul>
        {changedOptions.map((mo) => {
          const option = allOptions?.find((o) => o.id === mo);
          return <li key={mo}>{option ? option.name : `Option name not found - ${mo}`}</li>;
        })}
      </ul>
    </div>
  );
}
