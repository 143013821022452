import { Css, Icon, ModalHeader, useModal } from "@homebound/beam";
import { useCallback } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { CustomLoadingBoundary } from "src/components/LoadingBoundary";
import { SectionTitle } from "src/components/outdatedReadyPlanModal/components/atoms";
import { PlanTemplateMissingContent } from "src/components/outdatedReadyPlanModal/components/PlanTemplateMissingContent";
import { PlanTemplateOutdatedContent } from "src/components/outdatedReadyPlanModal/components/PlanTemplateOutdatedContent";
import { UnderwritingReport } from "src/routes/cma/endpoints/reports";
import { CmaStep } from "src/routes/cma/stepper/CmaStep";
import { CheckRPTemplateVersionResponse } from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlansEndpoint";
import { cmaPaths, createCmaReadyPlanUrl } from "src/routes/routesDef";

export type ReadyPlanOutdatedModalProps = {
  report: UnderwritingReport;
  checkResponse: CheckRPTemplateVersionResponse;
};

export function ReadyPlanOutdatedModal(props: ReadyPlanOutdatedModalProps) {
  const {
    report,
    checkResponse: { currentIncrement: latestBpRpVersion, devId },
  } = props;
  const { ready_plans, dpid, id } = report;
  const history = useHistory();
  const matchesRpStepPath = useRouteMatch(cmaPaths[CmaStep.ready_plan]);
  const { closeModal } = useModal();

  const currentUwRpVersion = ready_plans?.[0].bp_ready_plan_template_version;

  const onChooseNewRp = useCallback(() => {
    !matchesRpStepPath && history.push(createCmaReadyPlanUrl(dpid, id!.toString(), true));
    closeModal();
  }, [closeModal, dpid, history, id, matchesRpStepPath]);

  return (
    <>
      <ModalHeader>
        <div css={Css.df.aic.$}>
          Sync Ready Plan Template: {currentUwRpVersion} <Icon icon="arrowRight" /> {latestBpRpVersion ?? "??"}
        </div>
      </ModalHeader>
      {!!latestBpRpVersion ? (
        <CustomLoadingBoundary fallback={<SectionTitle label="Checking Options" loading />}>
          <PlanTemplateOutdatedContent
            report={report}
            onChooseNewRp={onChooseNewRp}
            currentIncrement={latestBpRpVersion}
            devId={devId!}
          />
        </CustomLoadingBoundary>
      ) : (
        <PlanTemplateMissingContent planName={ready_plans?.[0].bp_ready_plan_name} onChooseNewRp={onChooseNewRp} />
      )}
    </>
  );
}
