import { Css, Icon, Loader, ModalFooter, Palette, Tooltip } from "@homebound/beam";

// Common section title
export function SectionTitle({
  label,
  success = true,
  loading = false,
}: {
  label: string;
  loading?: boolean;
  success?: boolean;
}) {
  const TiCon = loading ? (
    <Loader size="xs" />
  ) : (
    <Icon icon={success ? "checkCircleFilled" : "error"} color={success ? Palette.Green400 : Palette.Red400} />
  );
  return (
    <div css={Css.base.df.aic.gap1.mb1.$}>
      {TiCon}
      <span>{label}</span>
    </div>
  );
}

// ModalFooter with outdated ready plan SOP tooltip
export const ModalFooterWTip = ({ children }: { children: React.ReactNode }) => (
  <ModalFooter xss={Css.jcsb.$}>
    <Tooltip
      title={
        <div css={Css.df.fdc.gap1.$}>
          <span>
            The ready plan used in this report must be up to date with BluePrint prior to report finalization.
          </span>
          <span>
            Any changes to configured options and/or program data require a new ready plan be configured in order to
            ensure accuracy and relevant comp generation.
          </span>
          <span>
            Cost only changes require your confirmation before being applied but do NOT require new ready plan
            configuration.
          </span>
        </div>
      }
    >
      <Icon icon="helpCircle" />
    </Tooltip>
    {children}
  </ModalFooter>
);
