import { Button, Css, FormLines, useComputed } from "@homebound/beam";
import { useFormState } from "@homebound/form-state";
import { useSuspense } from "@rest-hooks/react";
import { groupBy, partition } from "lodash";
import { Observer } from "mobx-react";
import { useMemo, useState } from "react";
import { useParams } from "react-router";
import { useAlertContext } from "src/components/AlertContext";
import { Icon } from "src/components/Icon";
import { LoadingBoundary } from "src/components/LoadingBoundary";
import { useController } from "src/hooks";
import { SaveUnderwritingReportEndpoint, UnderwritingReport } from "src/routes/cma/endpoints/reports";
import { StepperActions, useStepperContext } from "src/routes/cma/stepper";
import { OptionalOptionGroupsFormField } from "src/routes/cma/steps/ready-plan/v2/components/OptionalOptionGroupsFormField";
import { RequiredOptionGroupField } from "src/routes/cma/steps/ready-plan/v2/components/RequiredOptionGroupField";
import { BlueprintReadyPlanComputeDataEndpoint } from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlanComputeDataEndpoint";
import {
  BlueprintOptionGroupsEndpoint,
  BlueprintReadyPlanOptionGroup,
  ReadyPlanOption,
} from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlanOptionsEndpoint";
import {
  FinalizedReadyPlanOptionsPreview,
  LoadReadyPlanOptionsPreview,
} from "src/routes/cma/steps/ready-plan/v2/ReadyPlanOptionsPreview";
import { ConfigPreview } from "src/routes/cma/steps/ready-plan/v2/useConfigPreviewContext";
import { readyPlanStepIsReadOnly } from "src/utils/reports";
import { buildReadyPlanInputFromForm, mapToForm, v2ReadyPlanFormConfig, V2ReadyPlanFormState } from "./ReadyPlanForm";

interface LoadReadyPlanOptionsFormProps {
  devId: string;
  report: UnderwritingReport;
}

// TODO
//  - Beautify. This is last on the list of priorities
export function LoadReadyPlanOptionsForm({ devId, report }: LoadReadyPlanOptionsFormProps) {
  // We only care about showing rp option pricing when report hasn't been finalized
  useSuspense(BlueprintReadyPlanComputeDataEndpoint, {
    devId,
    bp_ready_plan_id: report.ready_plans![0].bp_ready_plan_id!,
    options: [],
  });

  const { optionGroups } = useSuspense(BlueprintOptionGroupsEndpoint, {
    devId,
    bp_ready_plan_id: report.ready_plans![0].bp_ready_plan_id!,
  });

  return (
    <LoadingBoundary>
      <ReadyPlanOptionsForm optionGroups={optionGroups} devId={devId} report={report} />
    </LoadingBoundary>
  );
}

interface ReadyPlanOptionsFormProps extends LoadReadyPlanOptionsFormProps {
  optionGroups: BlueprintReadyPlanOptionGroup[];
  report: UnderwritingReport;
}

export function ReadyPlanOptionsForm({ optionGroups, devId, report }: ReadyPlanOptionsFormProps) {
  const { fetch } = useController();
  const { goToNextStep } = useStepperContext();
  const { showInfo } = useAlertContext();

  const isReadOnly = readyPlanStepIsReadOnly(report);

  const [computeInProgress, setComputeInProgress] = useState(false);
  const reportReadyPlan = useMemo(() => report?.ready_plans?.[0], [report?.ready_plans]); // the actual one we care about
  const { dpid, versionId } = useParams<{ dpid: string; versionId: string }>();
  const { requiredOptionGroups, optionalOptionGroups } = useComputed(
    () => splitOptionGroups(optionGroups),
    [optionGroups],
  );
  // Collect all requiredOptionGroups options to prevent applying a default child incorrectly in the case that one
  //  requiredOptionGroup references another
  const allPossibleRequiredOptionIds = requiredOptionGroups.flatMap((d) => d.options.map((o) => o.id));

  const input = useComputed(() => ({ devId, optionGroups, reportReadyPlan }), [devId, optionGroups, reportReadyPlan]);

  const form = useFormState({
    config: v2ReadyPlanFormConfig,
    init: {
      input,
      map: mapToForm,
    },
    readOnly: isReadOnly,
  });

  async function onFinished() {
    const { report } = await saveForm();
    goToNextStep({ report, shouldRegenerateSteps: true });
  }

  async function saveForm(): Promise<{ report: UnderwritingReport }> {
    const readyPlan = buildReadyPlanInputFromForm(form.value, optionGroups);

    return await fetch(SaveUnderwritingReportEndpoint, {
      report: {
        dpid,
        ready_plans: [readyPlan],
      },
      versionId: versionId,
    });
  }

  // Resets optional groups no matter what
  function applyOrderedDefaults() {
    let addedDefaults: ReadyPlanOption[] = [];

    // Find the selected requiredOptionGroups and their full options
    const [requiredOptionSelectedIds, fullOptions] = findSelectedRequiredGroupsForDefaults(requiredOptionGroups, form);

    // Default children are always applied at the optionalOptionGroups level
    // Traverse required requiredOptionGroups to find defaults
    for (const option of fullOptions) {
      findDefaultChildren(option, addedDefaults, requiredOptionSelectedIds);
    }

    const addedDefaultIdsOnly = addedDefaults.map((o) => o.id);
    if (addedDefaultIdsOnly.length > 0) {
      form.optionalOptionGroups.set(addedDefaultIdsOnly);
      showInfo(
        <div css={Css.df.fdc.ml1.$}>
          {addedDefaults.map((o) => (
            <li key={o.id}>
              [{o.type.name}] {o.displayName}
            </li>
          ))}
        </div>,
      );
    } else {
      form.optionalOptionGroups.set([]);
      showInfo(
        <div css={Css.df.fdc.ml1.$}>
          <li>No defaults to apply.</li>
        </div>,
      );
    }
  }

  // Recursively finds all of an option defaults children that should be applied
  function findDefaultChildren(
    maybeOptionWithChild: ReadyPlanOption,
    acc: ReadyPlanOption[] = [],
    requiredOptionSelectedIds: string[] = [],
  ): ReadyPlanOption[] {
    if (maybeOptionWithChild.optionDefaultChildren.length > 0) {
      maybeOptionWithChild.optionDefaultChildren
        // Only try to apply active options
        .filter((co) => co.active)
        .forEach((child) => {
          // This should never be undefined
          // It should always exist because we assume all child options have an accompanying optionGroup
          const childOption = findReadyPlanOption(optionGroups, child.id);

          let shouldBeApplied = true;

          // Skip trying to apply this option if it is part of a requiredOptionGroup
          if (!allPossibleRequiredOptionIds.includes(childOption.id)) {
            // Confirm that at least one selected option per type from the optionDefaultsIf is selected
            if (childOption.optionDefaultsIf.length > 0) {
              const optionDefaultsIfByType = groupBy(childOption.optionDefaultsIf, (o) => o.type.name);
              for (const [, options] of Object.entries(optionDefaultsIfByType)) {
                shouldBeApplied =
                  shouldBeApplied &&
                  options.some((o) => requiredOptionSelectedIds.includes(o.id) || acc.some((a) => a.id === o.id));
              }
            }

            // Defaults are applied only if the optionPrerequisites are met
            if (childOption.optionPrerequisites.length > 0) {
              const preReqIds = childOption.optionPrerequisites.flatMap((preReq) => preReq.id);
              if (preReqIds.length > 0) {
                shouldBeApplied = preReqIds.every(
                  (id) => requiredOptionSelectedIds.includes(id) || acc.some((a) => a.id === id),
                );
              }
            }

            if (shouldBeApplied) {
              !acc.some((a) => a.id === childOption.id) && acc.push(childOption);
              return findDefaultChildren(childOption, acc, requiredOptionSelectedIds);
            }
          }
        });
    }
    return acc;
  }

  function renderForm() {
    // Block users from selecting optionalOptionGroups until required options are set
    const _requiredOptionGroupsSet = requiredOptionGroups.every((d) =>
      d.options.some((o) => form.requiredOptionGroups.value?.some((fd) => fd.selectedOptionId === o.id)),
    );

    return (
      <>
        <div>
          <FormLines compact>
            {requiredOptionGroups.map((d) => (
              <RequiredOptionGroupField key={d.name} optionGroup={d} form={form} />
            ))}
            {!isReadOnly && _requiredOptionGroupsSet && (
              <div css={Css.df.aic.gap1.py1.jcfe.$}>
                <Button
                  size="lg"
                  onClick={applyOrderedDefaults}
                  disabled={computeInProgress ?? "Computing costs after previous selection."}
                  tooltip={"Caution: You will lose any selections not a part of default set."}
                  label={"Apply Defaults Based on Min. Config"}
                />
              </div>
            )}
          </FormLines>
          {_requiredOptionGroupsSet && (
            <OptionalOptionGroupsFormField
              optionalOptionGroups={optionalOptionGroups}
              form={form}
              readOnly={isReadOnly}
            />
          )}
        </div>
        {isReadOnly ? (
          <ConfigPreview>
            <FinalizedReadyPlanOptionsPreview readyPlan={reportReadyPlan!} />
          </ConfigPreview>
        ) : (
          <>
            {_requiredOptionGroupsSet && (
              <ConfigPreview>
                <LoadReadyPlanOptionsPreview onLoading={setComputeInProgress} devId={devId} form={form} />
              </ConfigPreview>
            )}
            <StepperActions>
              <>
                <Button
                  endAdornment={<Icon icon="cloudSave" />}
                  label={"Save RP Configuration"}
                  disabled={!form.valid}
                  onClick={saveForm}
                  variant="secondary"
                />
                <Button label="Save &amp; Continue" disabled={!form.valid} onClick={onFinished} />
              </>
            </StepperActions>
          </>
        )}
      </>
    );
  }

  return <Observer>{() => renderForm()}</Observer>;
}

function splitOptionGroups(optionGroups: BlueprintReadyPlanOptionGroup[]) {
  const [required, optional] = partition(optionGroups, (og) => og.required);
  return {
    requiredOptionGroups: required.sort((a, b) => a.order - b.order),
    optionalOptionGroups: optional.sort((a, b) => a.order - b.order),
  };
}

function findReadyPlanOption(optionGroups: BlueprintReadyPlanOptionGroup[], optionId: string) {
  return optionGroups.flatMap(({ options }) => options).find((o) => o.id === optionId)!;
}

function findSelectedRequiredGroupsForDefaults(
  requiredOptionGroups: BlueprintReadyPlanOptionGroup[],
  form: V2ReadyPlanFormState,
): [string[], ReadyPlanOption[]] {
  const requiredOptionSelectedIds = form.requiredOptionGroups.rows.map((r) => r.selectedOptionId.value!);

  const fullOptions = form.requiredOptionGroups.rows.map((mrgRow) => {
    const selectedOptionId = mrgRow.selectedOptionId.value;
    // We need the full option that has all the `optionDefaultsIf` and `optionPrerequisites` to apply defaults correctly
    //   without updating gql query
    return findReadyPlanOption(requiredOptionGroups, selectedOptionId!);
  });

  return [requiredOptionSelectedIds, fullOptions];
}
