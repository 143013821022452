import { Entity } from "@rest-hooks/rest";
import { Endpoint } from "@rest-hooks/endpoint";
import { api_url, auth_headers } from "src/context";
import { Maybe } from "src/utils";

export class Lot extends Entity {
  readonly id: string = "";
  readonly dpid: string = "";
  readonly salesforceOpportunityId: string = "";
  readonly primeEstimateSoftCostInCents: number = 0;
  readonly primeEstimateHardCostInCents: number = 0;
  readonly primeEstimateOpcoMarkupInCents: number = 0;
  readonly primeEstimateReadyForClearToCloseReport: boolean = false;
  readonly primeEstimateBlueprintIds: Maybe<string[]> = undefined;
  readonly primeEstimateContingencyPriceInCents: number = 0;
  readonly contingencyExpirationDate: Maybe<string> = undefined;
  readonly underContractAmountInCents: number = 0;
  readonly underContractDate: Maybe<string> = undefined;
  readonly partitions: LotPartition[] = [];

  pk() {
    return this.id;
  }

  static key = "Lot";
}

export class LotPartition extends Entity {
  readonly listDate: Maybe<Date> = undefined;
  readonly readyPlanId: Maybe<string> = undefined;
  readonly readyPlanOptionIds: Maybe<string> = undefined;

  pk() {
    return this.readyPlanId ?? undefined;
  }
}

export const PropCoEndpoint = new Endpoint(
  async ({ opportunityId }: { opportunityId: string }) => {
    const headers = await auth_headers();
    const res = await fetch(api_url(`/v1/lots/${opportunityId}`), { headers });
    return await res.json();
  },
  {
    schema: { lot: Lot },
    key: ({ opportunityId }) => `/v1/lots/${opportunityId}`,
    name: "PropCoEndpoint",
  },
);
