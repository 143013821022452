import { Endpoint, Entity } from "@rest-hooks/rest";
import { api_url, auth_headers } from "src/context";

export interface BlueprintProgramData {
  stories: number;
  bedrooms: number;
  fullBaths: number;
  halfBaths: number;
  depthInInches: number;
  widthInInches: number;
  garageAttached: number;
  garagePort: number;
  sellableSqft: number;
  sellableAboveGroundSqft: number;
  sellableBelowGroundSqft: number;
  unfinishedBelowGroundSqft: number;
  // deprecated, use sellableBelowGroundSqft
  sellableBasementSqft: number;
  // deprecated, use sellableAboveGroundSqft
  aboveGroundSqft: number;
  // deprecated, use unfinishedBelowGroundSqft
  belowGroundSqft: number;
}

export interface BlueprintReadyPlanTypeDetail {
  name: string;
  code: string;
}

export interface BPReadyPlanStatusDetail {
  code: string;
}

export interface BlueprintReadyPlanTemplateDetail {
  id: string;
  name: string;
  version: string;
}

export interface BPReadyPlanAggregateVersion {
  id: string;
  name: string;
  version: number;
  status: BPReadyPlanStatusDetail;
}

export class BlueprintReadyPlan extends Entity {
  readonly id: string = "";
  readonly name: string = "";
  readonly type: BlueprintReadyPlanTypeDetail | undefined = undefined;
  readonly minProgramData: BlueprintProgramData | undefined = undefined;
  // v1 ready plans have a template that dictates which version is used for UW
  readonly underwritingTemplate: BlueprintReadyPlanTemplateDetail | undefined = undefined;
  // product offerings (v2) have aggregate versions and we will use the active one for UW
  readonly aggregateVersions: BPReadyPlanAggregateVersion[] = [];

  pk() {
    return this.id;
  }

  static key = "BlueprintReadyPlan";
}

export const BlueprintReadyPlansEndpoint = new Endpoint(
  async ({ devId }: { devId: string }) => {
    const headers = {
      "content-type": "application/json",
      ...(await auth_headers()),
    };
    const res = await fetch(api_url(`/v2/ready_plans/developments/${devId}/ready_plans`), {
      method: "GET",
      headers,
    });
    return await res.json();
  },
  {
    schema: [BlueprintReadyPlan],
    name: "BlueprintReadyPlansEndpoint",
  },
);

export type CheckRPTemplateVersionResponse = {
  isLatestVersion: boolean;
  currentIncrement: string | null;
  devId: string | null;
};

export async function checkBlueprintRPTemplateVersion({
  rpTemplateId,
  rpTemplateVersion,
}: {
  rpTemplateId: string;
  rpTemplateVersion: string;
}): Promise<CheckRPTemplateVersionResponse> {
  const headers = {
    "content-type": "application/json",
    ...(await auth_headers()),
  };
  const res = await fetch(api_url(`/v2/ready_plans/${rpTemplateId}/confirm-version/${rpTemplateVersion}`), {
    method: "POST",
    headers,
  });
  return await res.json();
}
