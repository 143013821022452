import { Button, Css, SelectField } from "@homebound/beam";
import { ObjectState, useFormState } from "@homebound/form-state";
import { LinearProgress } from "@material-ui/core";
import { capitalCase } from "change-case";
import { Observer } from "mobx-react";
import { useState } from "react";
import { useSuspense } from "@rest-hooks/react";
import { useController } from "src/hooks";
import { StepperActions, useStepperContext } from "src/routes/cma/stepper";
import { reportIsReadOnly } from "src/utils/reports";
import { Property } from "../../endpoints";
import {
  ReadyPlanEligInput,
  ReadyPlanOptions,
  ReadyPlanOptionsEndpoint,
} from "../../endpoints/ReadyPlanEligibilityEndpoint";
import { SaveUnderwritingReportEndpoint, UnderwritingReport } from "../../endpoints/reports";
import { READY_PLAN_SPEC_LEVELS } from "../readyPlanUtils";
import {
  formConfig,
  FormInput,
  mapFormToReadyPlanSaveReportInput,
  mapToForm,
  ReadyPlanSelectionForm,
} from "./ReadyPlanSelectionForm";

export interface LoadReadyPlanSelectOptionsProps {
  property: Property;
  report: UnderwritingReport;
}

export function LoadReadyPlanSelectOptions(props: LoadReadyPlanSelectOptionsProps) {
  const results = useSuspense(ReadyPlanOptionsEndpoint, mapFormToReadyPlanEligInput(props));
  const { goToNextStep } = useStepperContext();

  function onFinished(report: UnderwritingReport) {
    // TODO: only regenerate steps if the ready plan changed
    goToNextStep({ report, shouldRegenerateSteps: true });
  }

  return <ReadyPlanFormWrapper {...props} onFinished={onFinished} options={results} />;
}

function mapFormToReadyPlanEligInput({ report, property }: LoadReadyPlanSelectOptionsProps): ReadyPlanEligInput {
  const ref = report.reference_property;
  return {
    dpid: property.dpid,
    metro: property.metro,
    buildable_sqft: ref?.buildable_sqft ?? property.buildable_sqft!,
    lot_size: ref?.lot_size ?? property.lot_size,
    zoning: ref?.zoning ?? property.zoning,
    adu_elig: ref?.adu_elig ?? property.adu_elig,
    include_ineligible_plans: true,
  };
}

export interface ReadyPlanFormWrapperProps {
  report: UnderwritingReport;
  property: Property;
  options: ReadyPlanOptions;
  onFinished: (report: UnderwritingReport) => void;
}

function ReadyPlanFormWrapper({ property, report, onFinished, options }: ReadyPlanFormWrapperProps) {
  const { fetch } = useController();
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingSpecLevel, setIsSavingSpecLevel] = useState(false);
  const [specLevel, setSpecLevel] = useState(report.ready_plans?.[0]?.spec_level);

  const formState = useFormState({
    config: formConfig,
    init: {
      input: { report, property, options },
      map: mapToForm,
    },
    readOnly: reportIsReadOnly(report),
  });

  async function saveForm(): Promise<{ report: UnderwritingReport }> {
    setIsSaving(true);
    try {
      const reportInput = mapFormToReadyPlanSaveReportInput(formState);
      return await fetch(SaveUnderwritingReportEndpoint, { report: reportInput, versionId: report.id });
    } finally {
      setIsSaving(false);
    }
  }

  async function saveSpecLevel(spec_level: string): Promise<{ report: UnderwritingReport } | undefined> {
    setSpecLevel(spec_level);
    // no need to save if the ready plan hasn't been selected yet
    if (!formState.ready_plan_id.value) return;

    setIsSavingSpecLevel(true);
    const ready_plans = report.ready_plans?.map((rp) => ({ ...rp, spec_level }));

    try {
      return await fetch(SaveUnderwritingReportEndpoint, {
        report: { dpid: report.dpid, ready_plans },
        versionId: report.id,
      });
    } finally {
      setIsSavingSpecLevel(false);
    }
  }

  function renderForm() {
    return isSaving ? (
      <div css={Css.pt2.$}>
        <LinearProgress />
      </div>
    ) : (
      <>
        <div css={Css.mb2.wPx(250).$}>
          <SelectField
            label={"Spec Level"}
            placeholder={reportIsReadOnly(report) ? "" : "Select a spec level"}
            onSelect={(v) => saveSpecLevel(v ?? "")}
            options={READY_PLAN_SPEC_LEVELS.map((o) => ({ id: o, name: o }))}
            getOptionLabel={(o) => capitalCase(o.name)}
            getOptionValue={(o) => o.id}
            value={specLevel}
            required
            disabled={reportIsReadOnly(report) || isSavingSpecLevel}
          />
        </div>

        <ReadyPlanSelectionForm formState={formState} />
        <StepperActions>
          <Button
            label="Save &amp; Continue"
            disabled={getDisabledReason(formState, !!specLevel, isSavingSpecLevel)}
            onClick={async () => {
              const { report } = await saveForm();
              onFinished(report);
            }}
          />
        </StepperActions>
      </>
    );
  }

  return <Observer>{() => renderForm()}</Observer>;
}

function getDisabledReason(formState: ObjectState<FormInput>, hasSpecLevel: boolean, isSavingSpecLevel: boolean) {
  const hasReadyPlan = !!formState.ready_plan_id.value;

  if (!hasReadyPlan) {
    return "Please select a ready plan.";
  }

  if (!hasSpecLevel) {
    return "Please select a spec level.";
  }

  if (isSavingSpecLevel) return "Saving spec level...";

  if (!formState.dirty) {
    return "The ready plan does not have changes to save.";
  }

  return false;
}
