import { BoundCheckboxGroupField, CheckboxGroupItemOption, Css } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { groupBy } from "lodash";
import { CollapsibleGroup } from "src/components/CollapsibleGroup";
import { summarizeProgramData } from "src/routes/cma/steps/ready-plan/v2/components/summarizeProgramData";
import { BlueprintReadyPlanOptionDelta } from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlanComputeDataEndpoint";
import {
  BlueprintReadyPlanOptionGroup,
  ReadyPlanOption,
} from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlanOptionsEndpoint";
import { BlueprintProgramData } from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlansEndpoint";
import {
  getSelectedIdsFromForm,
  V2ReadyPlanFormInput,
  V2ReadyPlanFormState,
} from "src/routes/cma/steps/ready-plan/v2/ReadyPlanForm";
import { formatNumberToString, Maybe } from "src/utils";

interface OptionalOptionGroupsFormFieldProps {
  optionalOptionGroups: BlueprintReadyPlanOptionGroup[];
  form: ObjectState<V2ReadyPlanFormInput>;
  readOnly?: boolean;
}

type RPOForDisplay = ReadyPlanOption & {
  name: string;
  description: string;
  optionDelta?: BlueprintReadyPlanOptionDelta;
};

type GroupedOptions = { [key: string]: RPOForDisplay[] };

export function OptionalOptionGroupsFormField({
  optionalOptionGroups,
  form,
  readOnly = false,
}: OptionalOptionGroupsFormFieldProps) {
  const deltas = form.computeResults.value?.computeReadyPlanCost?.deltas ?? [];
  const optionsForDisplay: RPOForDisplay[] = optionalOptionGroups.flatMap((d) =>
    d.options.map((o) => {
      const optionDelta = deltas.find((d) => d.readyPlanOption.id === o.id);
      const description = programDataWDelta(o.programData, optionDelta);
      return { ...o, name: d.name, optionDelta, description };
    }),
  );

  // Visualization workaround until BP adds option dependencies and maybe some default options. Analysts are choosing
  // options based on SPREADSHEETS, we don't want to make it harder than it already is because BP API ain't perfect YET.
  let groupedOptionsInit: { [k: string]: any[] } = {};

  const groupedOptions: GroupedOptions = optionsForDisplay.reduce((acc: GroupedOptions, o) => {
    // IF we need to further group options based on some arbitrary string, IE anything but an actual property, we will
    // do it here. Or above too idk man im just a guy
    acc[o.type.name] ? acc[o.type.name].push(o) : (acc[o.type.name] = [o]);
    return acc;
  }, groupedOptionsInit);

  const formCheckboxGroups = Object.entries(groupedOptions)
    .map(([type, options]) => ({
      type,
      options: options
        .map((o, idx) => {
          const currentMissingPrereqs = optionalOptionGroupCheckPrerequisites(o, form);
          const currentConfigConflicts = getOptionalOptionGroupOptionConflicts(o, form);
          // Based on current form selections:
          //   - Show necessary prereq options (helperText) only when it disables option
          //   - Only show conflicts (errorMsg) if prereqs are met and conflict is selected causing option to be disabled
          return {
            label: o.displayName,
            value: o.id,
            description: o.description,
            // TODO: Removed while rolling out first markets
            // disabled: !!currentMissingPrereqs || !!currentConfigConflicts,
            helperText: currentMissingPrereqs,
            errorMsg: !!currentMissingPrereqs ? undefined : currentConfigConflicts,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
    }))
    .sort((a, b) => a.type.localeCompare(b.type));

  return (
    <div css={Css.dg.gtc(`repeat(1, 1fr)`).gap2.$}>
      {formCheckboxGroups.map((o) => (
        <GroupedOptionalOptionsFormGroup
          key={o.type}
          type={o.type}
          options={o.options}
          form={form}
          readOnly={readOnly}
        />
      ))}
    </div>
  );
}

interface GroupedOptionalOptionsProps {
  type: string;
  options: CheckboxGroupItemOption[];
  form: ObjectState<V2ReadyPlanFormInput>;
  readOnly?: boolean;
}

function GroupedOptionalOptionsFormGroup({ options, type, form, readOnly = false }: GroupedOptionalOptionsProps) {
  function _onChange(values: string[]) {
    if (readOnly) {
      return;
    }
    // Only call onOptionSelect when options are added, not removed.
    if (values.length > form.optionalOptionGroups.value!.length) {
      const addedValue = values.find((v) => !form.optionalOptionGroups.value!.includes(v));
      if (addedValue) {
        form.optionalOptionGroups.set(values);
      }
    } else {
      form.optionalOptionGroups.set(values);
    }
  }

  return (
    <>
      {readOnly ? (
        <div>
          <div css={Css.gray700.$}>{`${type} Options`}</div>
          {options.map((o: CheckboxGroupItemOption) => (
            <div key={o.value} css={Css.smMd.pt1.$}>
              {o.label}
            </div>
          ))}
        </div>
      ) : (
        <CollapsibleGroup label={`${type} Options`}>
          <BoundCheckboxGroupField
            columns={2}
            labelStyle="hidden"
            label={type}
            options={options}
            field={form.optionalOptionGroups}
            onChange={_onChange}
          />
        </CollapsibleGroup>
      )}
    </>
  );
}

function programDataWDelta(programData: Maybe<Partial<BlueprintProgramData>>, delta?: BlueprintReadyPlanOptionDelta) {
  const summary = summarizeProgramData(programData)
    ?.map((s) => s.summary)
    .join(", ");

  if (delta) {
    return `${formatCostDelta(delta.totalCostInCents)}${summary && ` | ${summary}`}`;
  } else {
    return summary;
  }
}

// TODO: We do have `FormattedPrice` BUT checkbox group doesn't support custom renderers
function formatCostDelta(cost: number): string {
  const isPositiveDelta = cost >= 0;
  return `∆ ${isPositiveDelta ? "$" : "-$"}${formatNumberToString(Math.abs(cost) / 100, false, false)}`;
}

// Returns a string of needed prereqs or false if prereqs are met or unneeded
function optionalOptionGroupCheckPrerequisites(option: ReadyPlanOption, form: V2ReadyPlanFormState) {
  if (option.optionPrerequisites.length === 0) {
    return false;
  }

  let missingPrereqs = [];

  const currentSelections = getSelectedIdsFromForm(form);
  const groupedPrereqs = groupBy(option.optionPrerequisites, (prereq) => prereq.type.name);
  const prereqentries = Object.entries(groupedPrereqs);

  for (const [type, prereqOptions] of prereqentries) {
    const hasPrereqFromType = prereqOptions.some((prereq) => currentSelections.includes(prereq.id));
    if (!hasPrereqFromType) {
      missingPrereqs.push(`${type}: (${prereqOptions.map((d) => d.displayName).join(" OR ")})`);
    }
  }

  return missingPrereqs.length > 0 ? missingPrereqs.join(", ") : false;
}

// Returns a string of conflicting options or false if no conflicts
export function getOptionalOptionGroupOptionConflicts(option: ReadyPlanOption, form: V2ReadyPlanFormState) {
  if (option.optionConflicts.length === 0) {
    return false;
  }

  let conflictingSelections = [];

  const currentSelections = getSelectedIdsFromForm(form);

  for (const { id, displayName } of option.optionConflicts) {
    if (currentSelections.includes(id)) {
      conflictingSelections.push(displayName);
    }
  }

  return conflictingSelections.length > 0 ? `Conflicting Selections: ${conflictingSelections.join(", ")}` : false;
}
