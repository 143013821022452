import { UnderwritingReport } from "src/routes/cma/endpoints/reports";
import { ActivityState } from "src/routes/cma/endpoints/reports/ActivityState";
import { postUwValuationStages } from "src/routes/cma/endpoints/reports/ValuationStage";
import { Maybe } from "./types";

export function reportIsReadOnly(report: Maybe<UnderwritingReport>): boolean {
  if (report === undefined || report === null) {
    return false;
  }

  if (report.activity_state !== ActivityState.active || report.status === "Finalized") {
    return true;
  }

  return false;
}

export function readyPlanStepIsReadOnly(report: Maybe<UnderwritingReport>): boolean {
  if (report === undefined || report === null) {
    return false;
  }

  if (reportIsReadOnly(report)) {
    return true;
  }

  if (report.valuation_stage && postUwValuationStages.includes(report.valuation_stage)) {
    return true;
  }

  return false;
}
