import { BoundSelectField } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { groupBy } from "lodash";
import { ReadyPlanOptionLabel } from "src/routes/cma/steps/ready-plan/v2/components/ReadyPlanOptionLabel";
import {
  BlueprintReadyPlanOptionGroup,
  ReadyPlanOption,
} from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlanOptionsEndpoint";
import {
  getSelectedIdsFromForm,
  V2ReadyPlanFormInput,
  V2ReadyPlanFormState,
} from "src/routes/cma/steps/ready-plan/v2/ReadyPlanForm";

interface RequiredOptionGroupFieldProps {
  optionGroup: BlueprintReadyPlanOptionGroup;
  form: ObjectState<V2ReadyPlanFormInput>;
}

export function RequiredOptionGroupField({ optionGroup, form }: RequiredOptionGroupFieldProps) {
  const options = optionGroup.options;
  const disabledOptions = getRequiredOptionGroupDisabledOptions(optionGroup, form);
  const field = form.requiredOptionGroups.rows.find((r) => r.name.value === optionGroup.name);

  if (!field) return null;

  return (
    <BoundSelectField<ReadyPlanOption, string>
      key={optionGroup.name}
      label={optionGroup.name}
      options={options}
      disabledOptions={disabledOptions}
      getOptionLabel={(o) => o.displayName}
      getOptionValue={(o) => o.id}
      field={field.selectedOptionId}
      getOptionMenuLabel={(o) => <ReadyPlanOptionLabel option={o} />}
      onSelect={(optionId: string | undefined) => {
        field.selectedOptionId.set(optionId);
      }}
    />
  );
}

// Based on prerequisites
export function getRequiredOptionGroupDisabledOptions(
  optionGroup: BlueprintReadyPlanOptionGroup,
  form: V2ReadyPlanFormState,
) {
  const options = optionGroup.options;
  const currentSelections = getSelectedIdsFromForm(form);
  const filteredOptions = options.filter((o) => {
    const groupedPrereqs = groupBy(o.optionPrerequisites, (prereq) => prereq.type.name);
    const prereqEntries = Object.entries(groupedPrereqs);

    const preReqsMet = prereqEntries.every(([type, prereqOptions]) => {
      return prereqOptions.some((prereq) => currentSelections.includes(prereq.id));
    });

    return !preReqsMet;
  });

  return filteredOptions.map((dOpt) => dOpt.id);
}
