import { Css, Tag } from "@homebound/beam";
import { BlueprintReadyPlan } from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlansEndpoint";
import { formatNumberToString } from "src/utils";
import { getReadyPlanVersion } from "../../../readyPlanUtils";

export function ReadyPlanNameLabel({ readyPlan }: { readyPlan: BlueprintReadyPlan }) {
  return (
    <div css={Css.w100.px2.$}>
      <div css={Css.lgBd.pb1.df.jcsb.$}>
        {readyPlan.name}
        <Tag text={`v ${getReadyPlanVersion(readyPlan)}`} />
      </div>
      <div css={Css.df.jcsb.sm.gray800.$}>
        <div>{readyPlan?.type?.name}</div>
        <div>{formatNumberToString(readyPlan.minProgramData?.sellableSqft ?? 0, true)} sqft</div>
        <div>{readyPlan.minProgramData?.bedrooms} beds</div>
        <div>
          {readyPlan.minProgramData?.fullBaths} full / {readyPlan.minProgramData?.halfBaths} half bath
        </div>
      </div>
    </div>
  );
}
